<template>
  <el-dialog
    :title="todo=='view' ? '【查看】车次信息' : '【修改】车次信息'"
    :close-on-click-modal="false" width="80%"
    :visible.sync="visible">
    <el-table :data="dataList" border size="mini" max-height="400" :span-method="arraySpanMethod"  style="width: 100%;">
      <af-table-column label="订单号" prop="orderInfo.orderNo" align="left">
      </af-table-column>
      <af-table-column label="进仓单号" prop="orderInfo.inWarehouseNo" align="left">
      </af-table-column>
      <af-table-column label="客户名称" prop="orderInfo.customerName" align="left" width="130">
      </af-table-column>
      <af-table-column label="客户单号" prop="orderInfo.customerSelfNo" align="left">
      </af-table-column>
      <af-table-column label="订单类型" :formatter="orderType" prop="orderInfo.orderType" align="left">
      </af-table-column>
      <af-table-column label="提货地址" prop="fullAddress" align="left">
      </af-table-column>
      <af-table-column label="卸货地址" prop="giveAddressInfo.fullAddress" align="left">
      </af-table-column>
      <af-table-column label="包装类型" :formatter="manyDictConvert" prop="orderGoods.packType" align="left">
      </af-table-column>
      <af-table-column label="提货时间" 提货方 prop="takeGoodsTime" align="left" width="130">
        <template slot-scope="scope">
          {{ scope.row.orderInfo.takeGoodsDate + ' ' + scope.row.orderInfo.takeGoodsTime}}
        </template>
      </af-table-column>
      <el-table-column prop="status" align="center" label="状态" width="70" fixed="right">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.orderInfo.status == 0 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small" type="danger">未提交</el-tag>
          <el-tag v-else-if="scope.row.orderInfo.status == 1 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small" type="warning">待审核</el-tag>
          <el-tag v-else-if="scope.row.orderInfo.status == 2 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small" type="warning">调度中</el-tag>
          <el-tag v-else-if="scope.row.orderInfo.status == 3 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small">已调度</el-tag>
          <el-tag v-else-if="scope.row.orderInfo.status == 8 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small" type="success">已完成</el-tag>
          <el-tag v-else-if="scope.row.orderInfo.status == 9 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small" type="danger">异常结束</el-tag>
          <el-tag v-else-if="scope.row.orderInfo.status == 10 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small" type="danger">审核拒绝</el-tag>
          <el-tag v-else-if="scope.row.orderInfo.status == 11 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small" type="danger">订单退回</el-tag>
          <el-tag v-else-if="scope.row.orderInfo.status == 12 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small" type="info">已取消</el-tag>
          <el-tag v-else-if="scope.row.status == 4" size="small">待出车</el-tag>
          <el-tag v-else-if="scope.row.status == 5" size="small" type="success">已出车</el-tag>
          <el-tag v-else-if="scope.row.status == 6" size="small" type="success">已到达</el-tag>
          <el-tag v-else-if="scope.row.status == 7" size="small" type="success">已装货</el-tag>
          <el-tag v-else-if="scope.row.status == 15" size="small" type="success">卸货到场</el-tag>
        </template>
      </el-table-column>
    </el-table>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">关闭</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data () {
    return {
      visible: false,
      dataForm: {
        id: null,
        motorcadeCode: null,
        companyId: null,
        fullName: null,
        simpleName: null,
        contactName: null,
        contactPhone: null,
        address: null,
        remark: null,
        status: 1
      },
      companyList: [],
      dataList: [],
      todo: 'view'
    }
  },
  computed: {
    dictTypeMap: {
      get () {
        return this.$store.state.common.dictTypeMap
      }
    }
  },
  methods: {
    init (row, todo) {
      this.todo = todo
      this.visible = true
      this.dataForm = row
      this.$http({
        url: this.$http.adornUrl(`/vehiclePlan/detailByOrderList`),
        method: 'GET',
        params: this.$http.adornParams({
          'id': row.id
        })
      }).then(({ data }) => {
        if (data) {
          this.getSpanArr(data)
          this.dataList = data
        } else {
          this.$message.error('操作失败')
        }
      })
    }
  }
}
</script>
